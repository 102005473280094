<template>
  <div>
    <NavBar
      style="max-height=50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 92vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">
            <h3 style="text-align:left; margin-left:65px; margin-top:30px; font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Change Customer Phone Number</h3>
            <v-card style="width:90%; margin-left:65px; height:100%; margin-top:20px;	height: 70vh; overflow:scroll; overflow-x: hidden;">
              <div style="display: flex; flex-direction: row; margin:10px" align="left">
                <div
                style="width: 100%; display: flex; flex-direction: column"
                align="left">

                <div style="margin-top: 5%">
                  <div style="width: 30%; margin-left: 35px; margin-top:12px;" align="left">Customer: <span style="font-size:14pt; font-weight:600">{{customer_name}}</span>
                  </div>
                </div>
                <div style="display: flex; flex-direction: row; margin-top:5%">
                  <div style="width: 30%;" align="left"><p  style="margin-left: 35px; margin-top:12px; font-size:10pt; font-weight:600"> Old Number</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Loading..."
                      solo
                      v-model="customer_current_number"
                      disabled
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row; margin-top:5%">
                  <div style="width: 30%;" align="left"><p  style="margin-left: 35px; margin-top:12px; font-size:10pt; font-weight:600"> New Number</p></div>
                  <div style="width: 50%; display: flex;" align="left">
                    <v-text-field
                      style="width: 70%; padding: 0px 10px 0px 0px;"
                      label="Enter new number"
                      solo
                      v-model="new_number"
                      :rules = "mobileNumberRules"
                    ></v-text-field>
                    <v-btn
                      style="padding: 0px 10px; width: min-content;"
                      :disabled = "isButtonClicked || isOTPClicked || !(new_number && new_number.length > 6 && new_number.length < 12)"
                      color = "warning"
                      @click="sendOTP"
                      @change="isButtonClicked = true"
                      >
                      <span v-if="resend_flag">Resend OTP</span>
                      <span v-else-if="!isOTPClicked">Send OTP</span>
                      <span v-else>Resend in {{minute_countdown}} seconds </span>
                    </v-btn>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row; margin-top:5%">
                  <div style="width: 30%;" align="left"><p  style="margin-left: 35px; margin-top:12px; font-size:10pt; font-weight:600"> Enter OTP</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Enter OTP"
                      solo
                      v-model="otp"
                      :disabled = "isOTPDisabled"
                    ></v-text-field>
                  </div>
                </div>

                <div align="center">
                  <v-btn
                    @click="changeNumber"
                    style=" width:50%; height: 40px; margin-right:10%; margin-top:20px;"
                    color="primary"
                    :disabled="changeDetect()"
                    >Change Number</v-btn>
                </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      currentUserName: "",
      currentUser: "",
      user_id: "",
      new_number: "",
      otp: "",
      customer_id: null,
      customer_name: null,
      customer_current_number: null,
      pageName: "changeNumber",
      isButtonClicked : false,
      isOTPClicked: false,
      isOTPDisabled: true,
      mobileNumberRules: [
      v => !!v || 'Phone Number is required',
      v => (v.length > 6 && v.length < 12) || 'Phone Number should be between 7 to 11 digits',
      v => v > 0 || 'Phone Number cannot be negative',
      ],
      minute_countdown: 30,
      resend_flag: false,
      timer: null,
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var params = this.$route.params;
      var customer_id = params['customer_id'];
      this.customer_id = customer_id;
      var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        adminType: "ADMIN",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getAccessData)
        .then((getAdminResponse) => {
          this.currentUserName = getAdminResponse.data.data.admin_name;
          this.user_id = getAdminResponse.data.data._id;
          var customerBody = {
            'customerId' : this.customer_id,
            'token': this.currentUser,
            'typeOfUser': "ADMIN"
          };
          axios_auth_instance_admin.post("/readCustomer", customerBody)
          .then((customerResponse) => {
            this.customer_name = customerResponse.data.data.customer_name;
            this.customer_current_number = customerResponse.data.data.phoneNumber;
          })
          .catch((customerError) => {
            handleError(customerError, this.$router, 'customerError', 'AdminLogin', 'token');
          });
        })
        .catch((getAdminError) => {
          if (getAdminError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          }
          else if(getAdminError.response.status == 400)
          {
            window.alert(getAdminError);
          }
        });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    changeDetect()
    {
      return (this.isButtonClicked || !(this.isOTPClicked && this.new_number.length > 6 && this.new_number.length < 12 && this.otp.length == 6))
    },
    sendOTP()
    {
      if(this.new_number.trim().length > 6 && this.new_number.trim().length < 12)
      {
        this.isOTPClicked = true;
        this.minute_countdown = 30;
        this.resend_flag = false;
        this.timer = setInterval(() => {
          this.minute_countdown = this.minute_countdown - 1;
          if(this.minute_countdown <= 0)
          {
            this.resend_flag = true;
            this.isOTPClicked = false;
            clearInterval(this.timer);
          }
        }, 1000);
        var sendCustomerOTPBody = {
          'phoneNumber' : this.new_number.trim(),
          'token': this.currentUser,
          'typeOfUser': "ADMIN"
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/sendChangeOTP", sendCustomerOTPBody)
        .then((customOTPSuccess) => {
          this.isOTPDisabled = false;
        })
        .catch((customOTPError) => {
          console.log(customOTPError);
          if(customOTPError.response.status == 305)
          {
            window.alert("Phone number already exists");
            this.new_number = "";
            this.otp = "";
            this.isButtonClicked = false;
            this.isOTPClicked = false;
            clearInterval(this.timer);
          }
          else
          {
            window.alert("Something went wrong");
            clearInterval(this.timer);
          }
        });
      }
      else
      {
        alert("Please input number");
      }
    },
    changeNumber() {
      this.isButtonClicked = true
      var changeNumberBody = {
        'customerId': this.customer_id,
        'old_number': this.customer_current_number,
        'new_number': this.new_number.trim(),
        'otp': this.otp,
        'token': this.currentUser,
        'typeOfUser': "ADMIN",
      };
      if (this.new_number.trim() != "" && this.new_number.length >= 7 && this.new_number.length <= 11) {
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/changeCustomerNumber",
            changeNumberBody
          )
          .then((changeNumberResponse) => {
            alert("Phone number changed successfully");
            this.$router.push({
              name: 'ViewAllPatients'
            });
          })
          .catch((changeNumberError) => {
            this.isButtonClicked = true
            if (changeNumberError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "AdminLogin",
              });
            }
            if (changeNumberError.response.status == 305) {
              window.alert("Phone number already exists");
              this.new_number = "";
              this.otp = "";
              this.isButtonClicked = false;
              this.isOTPClicked = false;
              clearInterval(this.timer);
            }
            if (changeNumberError.response.status == 306) {
              window.alert("Wrong OTP recieved");
              this.otp = "";
              this.isButtonClicked = false;
              this.resend_flag = false;
              this.isOTPClicked = false;
              clearInterval(this.timer);
            }
            else
            {
              window.alert("Something went wrong");
              console.log(changeNumberError);
              this.new_number = "";
              this.otp = "";
              this.isButtonClicked = false;
              this.isOTPClicked = false;
              clearInterval(this.timer);
            }
          });
      } else {
        alert("Please provide all the details");
      }
    },
  },
};
</script>

  <style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>